import React from 'react';
import { Link } from 'react-router-dom';
import './TitleBar.css'; // Import the CSS file

// TitleBar Component with style variants
const TitleBar = ({ styleVariant = 'default' }) => {
  // Determine the class based on the styleVariant prop
  const className = `titleBar ${styleVariant}`;

  return (
    <div className={className}>
      <Link to="/" className="link">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          width="24px"
          height="24px"
          className="icon"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" />
        </svg>
      </Link>
    </div>
  );
};

export default TitleBar;
