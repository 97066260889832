// ConfirmationPage.js
import React from 'react';
import './ConfirmationPage.css'; // Import the shared CSS file for styling

const ConfirmationPage = ({ customer, licenseStartDate, licenseEndDate, licenseId, onBack }) => {
  return (
    <div className="confirmation-page">
      <h2>License Successfully Issued</h2>
      <div className="confirmation-details">
        <div className="row">
          <div className="label">Customer</div>
          <div className="value">{customer}</div>
        </div>
        <div className="row">
          <div className="label">Start Date</div>
          <div className="value">{licenseStartDate}</div>
        </div>
        <div className="row">
          <div className="label">End Date</div>
          <div className="value">{licenseEndDate}</div>
        </div>
        <div className="row">
          <div className="label">License ID</div>
          <div className="value">{licenseId}</div>
        </div>
      </div>
      <button onClick={onBack} className="back-button">
        Back to License Form
      </button>
    </div>
  );
};

export default ConfirmationPage;
