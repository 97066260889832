import React, { useState } from 'react';
import './AddPatientModal2.css'; // Import the CSS file

const AddPatientModal = ({ onSave, onCancel }) => {
    const [newPatient, setNewPatient] = useState({
        name: '',
        dob: '', genderWithInsurance: '', phoneNumber: '',
        address: '',
        diagnosis: '', numberOfRequestedVisits: '', treatmentNotes: '', referringPhysician: ''
    });

    const handleChange = (field, value) => {
        setNewPatient(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = () => {
        onSave(newPatient);
        setNewPatient({
            name: '',
            dob: '', genderWithInsurance: '', phoneNumber: '',
            address: '',
            diagnosis: '', numberOfRequestedVisits: '', treatmentNotes: '', referringPhysician: ''
        }); // Reset form
    };

    return (
        <div className="modal">
            <div className="modalHeader">
                <h2>Create New Patient</h2>
            </div>
            <div className="buttonsContainer">
                <div className="leftButtons">
                    <button className="button newButton" onClick={() => handleSubmit('update')}>New</button>
                    <button className="button updateButton shiftRight" onClick={() => {}}>Update</button>
                </div>
                <button className="button cancelButton2" onClick={onCancel}>&times;</button>
            </div>
            <div className='modalBody'>
                <div className="row">
                    <div className="inputGroup">
                        <label>Name</label>
                        <input type="text" value={newPatient.name} onChange={e => handleChange('name', e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Date of Birth</label>
                        <input type="date" value={newPatient.dob} onChange={e => handleChange('dob', e.target.value)} />
                    </div>
                    <div className="inputGroup">
                        <label>Gender</label>
                        <select
                            value={newPatient.genderWithInsurance}
                            onChange={e => handleChange('genderWithInsurance', e.target.value)}
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Nonbinary">Nonbinary</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Phone Number</label>
                        <input type="text" value={newPatient.phoneNumber} onChange={e => handleChange('phoneNumber', e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Address</label>
                        <input type="text" value={newPatient.address} onChange={e => handleChange('address', e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Referring Physician</label>
                        <input type="text" value={newPatient.referringPhysician} onChange={e => handleChange('referringPhysician', e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Diagnosis</label>
                        <input type="text" value={newPatient.diagnosis} onChange={e => handleChange('diagnosis', e.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="textareaGroup">
                        <label>Treatment Notes</label>
                        <textarea value={newPatient.treatmentNotes} onChange={e => handleChange('treatmentNotes', e.target.value)} rows="3"></textarea>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPatientModal;