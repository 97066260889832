const productData = [
    // { productName: "Fixed-Base Assembly System", productCode: "" },
    // { productName: "GenWatt Diesel 1000kW", productCode: "GC1060" },
    // { productName: "GenWatt Diesel 10kW", productCode: "GC1020" },
    // { productName: "GenWatt Diesel 200kW", productCode: "GC1040" },
    // { productName: "GenWatt Gasoline 2000kW", productCode: "GC5060" },
    // { productName: "GenWatt Gasoline 300kW", productCode: "GC5020" },
    // { productName: "GenWatt Gasoline 7500kW", productCode: "GC5040" },
    // { productName: "GenWatt Propane 100kW", productCode: "GC3020" },
    // { productName: "GenWatt Propane 1500kW", productCode: "GC3060" },
    // { productName: "GenWatt Propane 500kW", productCode: "GC3040" },
    // { productName: "Installation: Industrial - High", productCode: "IN7080" },
    // { productName: "Installation: Industrial - Low", productCode: "IN7040" },
    // { productName: "Installation: Industrial - Medium", productCode: "IN7060" },
    // { productName: "Installation: Portable", productCode: "IN7020" },
    { productName: "Large CloudyBot", productCode: "" },
    { productName: "Large RainbowBot", productCode: "" },
    { productName: "Medium CloudyBot", productCode: "" },
    { productName: "Medium RainbowBot", productCode: "" },
    { productName: "Small CloudyBot", productCode: "" },
    { productName: "Small RainbowBot", productCode: "" },
    { productName: "SL: Bronze", productCode: "SL9020" },
    { productName: "SL: Gold", productCode: "SL9060" },
    { productName: "SL: Platinum", productCode: "SL9080" },
    { productName: "SL: Silver", productCode: "SL9040" }
  ]

  export default productData;