import React from 'react';
import TitleBar from '../shared/TitleBar';
import VerticalTabs from './VerticalTabs';
import Content from './Content';
import '../index.css';

const EmrHome = () => {
    return (
        <div style={styles.content}>
            <TitleBar />
            <VerticalTabs />
            <Content />
        </div>
    );
};

const styles = {
    content: {
        // marginLeft: '220px',
        // padding: '20px',
        // marginTop: '70px',
    },
};

export default EmrHome;