import React, { useState } from 'react';
import TitleBar from '../shared/TitleBar';
import LicenseForm from './LicenseForm';

const LicenseHome = () => {
    return (
        <div>
            <TitleBar styleVariant="lightPurple" />
            <LicenseForm />
        </div>
    );
};

export default LicenseHome;