import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import EmrHome from './emr/EmrHome';
import Home from './home/Home';
import LicenseHome from './licensing/LicenseHome';

const baseUrl = process.env.REACT_APP_BASE_URL;

const App = () => {
  return (
    <Router basename={baseUrl}>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/emr/*" element={<EmrHome />} />
        <Route path="/loanterms/" component={() => {
          window.location.href = '/loanterms/index.html';
          return null;
        }} />
        <Route path="/licensing/*" element={<LicenseHome />} />
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);