import React, { useState, useEffect } from 'react';
import TitleBar from '../shared/TitleBar';
import customerData from '../data/customerData';
import productData from '../data/productData';
import ConfirmationPage from './ConfirmationPage'; // Import the ConfirmationPage component
import './LicenseForm.css';

const LicenseForm = () => {
  const [customers, setCustomers] = useState(customerData);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [licenseStartDate, setLicenseStartDate] = useState('');
  const [licenseEndDate, setLicenseEndDate] = useState('');
  const [skuQuantities, setSkuQuantities] = useState({});
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [licenseId, setLicenseId] = useState('');

  // Extract customer names and SKUs
  const customerNames = customers.map(customer => customer.name);
  const skus = productData.map(product => product.productName).sort((a, b) => a.localeCompare(b));;

  useEffect(() => {
    const today = new Date();
    const startDate = today.toISOString().split('T')[0];
    const endDate = new Date(today.setFullYear(today.getFullYear() + 1)).toISOString().split('T')[0];
    setLicenseStartDate(startDate);
    setLicenseEndDate(endDate);
  }, []);

  const handleCustomerChange = (e) => {
    setSelectedCustomer(e.target.value);
  };

  const handleDateChange = (e, isStart) => {
    isStart ? setLicenseStartDate(e.target.value) : setLicenseEndDate(e.target.value);
  };

  const handleQuantityChange = (sku, quantity) => {
    setSkuQuantities({ ...skuQuantities, [sku]: parseInt(quantity, 10) });
  };

  const generateLicenseId = () => {
    return Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);
  };

  const handleSubmit = () => {
    const newLicense = {
      licenseId: generateLicenseId(),
      startDate: licenseStartDate,
      endDate: licenseEndDate,
      skuQuantities: { ...skuQuantities },
    };

    setCustomers(prevCustomers => {
      return prevCustomers.map(customer => {
        if (customer.name === selectedCustomer) {
          return {
            ...customer,
            licenses: [...customer.licenses, newLicense],
          };
        }
        return customer;
      });
    });

    setLicenseId(newLicense.licenseId);
    setIsConfirmation(true);
  };

  const handleBack = () => {
    setIsConfirmation(false);
  };

  const isSubmitEnabled = () => {
    const hasSelectedCustomer = selectedCustomer.trim() !== '';
    const hasValidDates = licenseStartDate && licenseEndDate;
    const hasValidQuantities = Object.values(skuQuantities).some(quantity => quantity > 0);

    return hasSelectedCustomer && hasValidDates && hasValidQuantities;
  };

  const copyLicenseData = (historicalData) => {
    setSkuQuantities(historicalData);
  };

  const submitButtonClass = `submit-button ${!isSubmitEnabled() ? 'disabled' : ''}`;

  const selectedCustomerRecord = customers.find(customer => customer.name === selectedCustomer);
  const licenseHistory = selectedCustomerRecord ? [...selectedCustomerRecord.licenses] : [];

  licenseHistory.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

  if (isConfirmation) {
    return (
      <ConfirmationPage
        customer={selectedCustomer}
        licenseStartDate={licenseStartDate}
        licenseEndDate={licenseEndDate}
        licenseId={licenseId}
        onBack={handleBack}
      />
    );
  }

  return (
    <div className="license-form">
      <TitleBar styleVariant="lightPurple" />
      <div className="content">
        <div className="column">
          <div className="section">
            <h3>Choose a Customer</h3>
            <input
              type="text"
              placeholder="Search customers ..."
              list="customers"
              value={selectedCustomer}
              onChange={handleCustomerChange}
              className="input-field"
            />
            <datalist id="customers">
              {customerNames.map((customer, index) => (
                <option key={index} value={customer} />
              ))}
            </datalist>
          </div>

          <div className="section">
            <h3>License Date</h3>
            <div className="date-inputs">
              <div>
                <label>License Start Date</label>
                <input
                  type="date"
                  value={licenseStartDate}
                  onChange={(e) => handleDateChange(e, true)}
                  className="input-field"
                />
              </div>
              <div>
                <label>License End Date</label>
                <input
                  type="date"
                  value={licenseEndDate}
                  onChange={(e) => handleDateChange(e, false)}
                  className="input-field"
                />
              </div>
            </div>
          </div>

          {licenseHistory.length > 0 && (
  <div className="section">
    <h3>License History</h3>
    <div className="history-list">
      <div className="license-history-header">
        <div className="column header">Start</div>
        <div className="column header">End</div>
        <div className="column header">ID</div>
        <div className="column header"></div> {/* Unlabeled column for the button */}
      </div>
      {licenseHistory.map((license, index) => (
        <div key={index} className="license-history-item">
          <div className="column">{license.startDate}</div>
          <div className="column">{license.endDate}</div>
          <div className="column">{license.licenseId}</div>
          <div className="column">
            <button
              onClick={() => copyLicenseData(license.skuQuantities)}
              className="copy-link"
            >
              Use SKU Data
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
)}


        </div>

        <div className="column">
          <div className="section">
            <h3 className='sku-list-title'>Enter SKU Quantities</h3>
            <div className="sku-list">
              {skus.map((sku, index) => (
                <div key={index} className="sku-item">
                  <span>{sku}</span>
                  <input
                    type="number"
                    placeholder="Quantity"
                    min="0"
                    value={skuQuantities[sku] || ''}
                    onChange={(e) => handleQuantityChange(sku, e.target.value)}
                    className="quantity-input"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <button
          onClick={handleSubmit}
          className={submitButtonClass}
          disabled={!isSubmitEnabled()}
        >
          Issue License
        </button>
      </div>
    </div>
  );
};

export default LicenseForm;
