import React from 'react';
import { Link } from 'react-router-dom';

const VerticalTabs = () => {
  return (
    <div style={styles.verticalTabs}>
      <Link to="/emr" exact style={styles.tabLink} activestyle={styles.activeTabLink}>Dashboard</Link>
      <Link to="/emr" style={styles.tabLink} activestyle={styles.activeTabLink}>On Deck</Link>
      <Link to="/emr"style={styles.tabLink} activestyle={styles.activeTabLink}>Scheduling</Link>
      <Link to="/emr/patients" style={styles.activeTabLink} activeStyle={styles.activeTabLink}>Patients</Link>
      <Link to="/emr" style={styles.tabLink} activestyle={styles.activeTabLink}>Reports</Link>
      <Link to="/emr" style={styles.tabLink} activestyle={styles.activeTabLink}>Library</Link>
      <Link to="/emr" style={styles.tabLink} activestyle={styles.activeTabLink}>Address Book</Link>
    </div>
  );
};

const styles = {
    verticalTabs: {
      width: '200px',
      height: '100vh',
      backgroundColor: '#003366',  
      padding: '10px',
      position: 'fixed',
      top: '50px',
      left: 0,
      overflowY: 'auto',
    },
    tabLink: {
      display: 'block',
      padding: '10px',
      textDecoration: 'none',
      color: 'white',
      marginBottom: '10px',
    },
    activeTabLink: {
      backgroundColor: 'royalblue',  // Changed to royal blue for the selected tab
      display: 'block',
      padding: '10px',
      textDecoration: 'none',
      color: 'white',
      marginBottom: '10px',
    },
  };

export default VerticalTabs;
