import React, { useState } from 'react';
import AddPatientModal from './AddPatientModal2';
import './Patients.css'; // Import the CSS file

const initialPatients = [
    { id: 1, name: 'Alice Q. Johnson', dob: '1984-05-23', genderWithInsurance: 'Female', phoneNumber: '555-1234', address: '1234 Main St, Anytown, CA, 90210', diagnosis: 'Rotator cuff surgery', numberOfRequestedVisits: 16, treatmentNotes: 'Rehab shoulder', referringPhysician: 'Clara Huxley, MD' },
    { id: 2, name: 'Bob L. Smith', dob: '1978-04-17', genderWithInsurance: 'Male', phoneNumber: '555-5678', address: '4321 First St, Anytown, CA, 90210', diagnosis: 'Back Pain', numberOfRequestedVisits: 3, treatmentNotes: 'Initial assessment required', referringPhysician: 'Dr. Marcus Wellborne' },
    { id: 3, name: 'Charlie M. Brown', dob: '1990-08-30', genderWithInsurance: 'Male', phoneNumber: '555-8765', address: '987 Elm St, Othertown, TX, 30022', diagnosis: 'Knee Pain', numberOfRequestedVisits: 8, treatmentNotes: 'Eval required', referringPhysician: 'Evelyn Storrow, D.O.' },
    { id: 4, name: 'Diana F. Prince', dob: '1982-07-09', genderWithInsurance: 'Female', phoneNumber: '555-4321', address: '321 Fourth St, Sometown, NY, 10001', diagnosis: 'Pulled hamstring', numberOfRequestedVisits: 2, treatmentNotes: 'Include massage', referringPhysician: 'Dr. Leo Carrington' },
    { id: 5, name: 'Eva G. Green', dob: '1975-03-20', genderWithInsurance: 'Female', phoneNumber: '555-9876', address: '654 Pine St, Anothertown, FL, 33101', diagnosis: 'Sprained ankle', numberOfRequestedVisits: 1, treatmentNotes: 'Apply ice, rest', referringPhysician: 'Nora Fieldstone, M.D.' },
    { id: 6, name: 'Frank H. Ocean', dob: '1987-10-28', genderWithInsurance: 'Male', phoneNumber: '555-1122', address: '5678 Maple St, Heretown, WA, 98101', diagnosis: 'Repaired achilles tendon', numberOfRequestedVisits: 24, treatmentNotes: 'See 2x per week for 12 weeks', referringPhysician: 'Isaiah Morrow' },
    { id: 7, name: 'Grace I. Hopper', dob: '1952-12-09', genderWithInsurance: 'Female', phoneNumber: '555-2233', address: '321 Cedar St, Wheretown, CO, 80201', diagnosis: 'Knee replacement', numberOfRequestedVisits: 24, treatmentNotes: '3x per week for 8 weeks', referringPhysician: 'Julia Bancroft, DO' },
    { id: 8, name: 'Alex P. Person', dob: '1981-06-15', genderWithInsurance: 'Nonbinary', phoneNumber: '555-3344', address: '213 Oak St, Thistown, IL, 60601', diagnosis: 'Muscle weakness in legs', treatmentNotes: 'Work on strenghtening legs', referringPhysician: 'Theodore Raines, MD' },
];

const Patients2 = () => {
    const [patients, setPatients] = useState(initialPatients);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const addPatient = (newPatient) => {
        newPatient.id = patients.length + 1; // Simple ID assignment
        setPatients([...patients, newPatient]);
        setShowModal(false);
    };

    return (
        <div style={styles.container}>
            {showModal && <div style={styles.overlay}></div>}
            <div className="listContainer">
                <div className="buttonContainer">
                    <button className="button addPatientButton" onClick={() => setShowModal(true)}>Add Patient</button>
                </div>
                <h2>Patients</h2>
                <ul style={styles.list}>
                    {patients.map(patient => (
                        <li
                            key={patient.id}
                            style={selectedPatient && selectedPatient.id === patient.id ? styles.selectedItem : styles.listItem}
                            onClick={() => setSelectedPatient(patient)}
                        >
                            {`${patient.name}`}
                        </li>
                    ))}
                </ul>
            </div>
            <div style={styles.detailsContainer}>
                {selectedPatient && (
                    <div style={styles.details}>
                        <h3>Details</h3>
                        <p><strong>Name:</strong> {`${selectedPatient.name}`}</p>
                        <p><strong>Date of Birth:</strong> {selectedPatient.dob}</p>
                        <p><strong>Gender:</strong> {selectedPatient.genderWithInsurance}</p>
                        <p><strong>Phone Number:</strong> {selectedPatient.phoneNumber}</p>
                        <p><strong>Address:</strong> {`${selectedPatient.address}`}</p>
                        <p><strong>Referring Physician:</strong> {selectedPatient.referringPhysician}</p>
                        <p><strong>Diagnosis:</strong> {selectedPatient.diagnosis}</p>
                        <p><strong>Number of Requested Visits:</strong> {selectedPatient.numberOfRequestedVisits}</p>
                        <p><strong>Treatment Notes:</strong> {selectedPatient.treatmentNotes}</p>
                    </div>
                )}
            </div>
            {showModal && <AddPatientModal onSave={addPatient} onCancel={() => setShowModal(false)} />}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        height: '88vh',
        position: 'relative',
    },
    listContainer: {
        width: '33.33%',
        padding: '10px',
        boxSizing: 'border-box',
        overflowY: 'auto',
        height: '100%',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
        marginTop: '20px',
    },
    listItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid gray',
    },
    selectedItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid gray',
        backgroundColor: 'lightblue',
    },
    detailsContainer: {
        width: '66.67%',
        padding: '20px',
        boxSizing: 'border-box',
        overflowY: 'auto',
        height: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2,
    },
    addButton: {
        display: 'block',
        margin: '10px 0',
    },
};

export default Patients2;