// customerData.js

const customerData = [
    {
      name: 'Burlington Textiles Corp of America',
      licenses: [
        {
          licenseId: 'll9cgt1dvxh6yz8m',
          startDate: '2021-06-01',
          endDate: '2022-06-01',
          skuQuantities: {
            'Large CloudyBot': 10,
            'Small RainbowBot': 20,
            'SL: Silver': 1
          },
        },
        {
          licenseId: 'ozoynzc7wf3yje07',
          startDate: '2022-07-15',
          endDate: '2023-07-15',
          skuQuantities: {
            'Large CloudyBot': 5,
            'Small RainbowBot': 10,
            'SL: Gold': 1
          },
        },
      ],
    },
    {
      name: 'Edge Communications',
      licenses: [
        {
          licenseId: '5q527yoa4ihppe3g',
          startDate: '2020-03-10',
          endDate: '2021-03-10',
          skuQuantities: {
            'Medium CloudyBot': 7,
            'Medium RainbowBot': 5,
            'SL: Bronze': 1
          },
        },
        {
          licenseId: 'p6avbasa108kop9w',
          startDate: '2023-01-05',
          endDate: '2024-01-05',
          skuQuantities: {
            'Large CloudyBot': 7,
            'Large RainbowBot': 5,
            'SL: Silver': 1
          },
        },
      ],
    },
    {
      name: 'Express Logistics and Transport',
      licenses: [
        {
          licenseId: 'cc678j4ghxpthzyj',
          startDate: '2019-11-20',
          endDate: '2020-11-20',
          skuQuantities: {
            'Small CloudyBot': 18,
            'SL: Gold': 1,
          },
        },
        {
          licenseId: 'jcnb8hmn25uds4yw',
          startDate: '2022-08-01',
          endDate: '2023-08-01',
          skuQuantities: {
            'Large CloudyBot': 100,
            'Large RainbowBot': 100,
            'SL: Platinum': 1,
          },
        },
      ],
    },
  ];
  
  export default customerData;
  