import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="container">
      <Link to="/emr" className="tile">
        <div className="tile-content">
          <h2>Electronic Medical Records</h2>
        </div>
      </Link>
      <a href="/loanterms" className="tile">
        <div className="tile-content">
          <h2>Loan Calculator</h2>
        </div>
      </a>
      <Link to="/licensing" className="tile">
        <div className="tile-content">
          <h2>License Tool</h2>
        </div>
      </Link>
    </div>
  );
};

export default Home;
